<template>
  <v-card color="#fff" class="grey--text">
    <v-card-title>
      <v-row dense>
        <v-col>
          Sugestões 
        </v-col>
        <v-col class="mr-8 text-right">
          <v-btn-toggle v-model="toggle_exclusive" mandatory>
            <v-btn :loading="!loading_papeis" v-for="(p, i) in permissoes.papel" :key="`p-${i}`"
              :class="toggle_exclusive == i ? 'white--text mx-1 font-weight-bold' : 'black--text mt-1'"
              :elevation="toggle_exclusive == i ? 8 : 0" :color="toggle_exclusive == i ? p.cor : '#dbd7d7'"
              @click="escolhePapel(p.id)">{{ p.text }}
              <v-icon> {{ p.icon }}</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-container v-if="colaborador">
        <Colaborador :is-ava="avaliador" :is-adm="administrador"/>
      </v-container>
      <v-container v-if="avaliador">
        <Avaliador />
      </v-container>
      <!--
      <v-container v-if="administrador">
        <Administrador :is-ava="avaliador" :is-adm="administrador" />
      </v-container>
    -->
    </v-card-text> 
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
// import httpApiService from '../../../http'
// import httpApiService from '@/http'
/* implementar nesta tela, dados do usuario para sua garantia, assim como dados de prazos e regras. */
export default {
  name: 'Sugestoes',
  components: {
    Colaborador:()=>import ('./Colaborador.vue'),
    // Administrador: () => import('./Administrador'),
    Avaliador: () => import('./Avaliador'),
    
  },
  data() {
    return {
      toggle_exclusive: 0,
      tab: null,
      avaliador: false,
      colaborador: false,
      administrador: false,
      cfg: JSON.parse(localStorage.getItem('cfgu')),
      perms: [1, 2, 3]
    }
  },
  computed: {
    ...mapGetters(['perm','permissoes']),
    loading_papeis(){
      return this.permissoes.papel.length>0
    }
  },
  methods: {
    // to do #091120221150  - é preciso criar no backend 
    escolhePapel(val) {
      if (val == 1) {
        this.colaborador = true
        this.avaliador = false
        this.administrador = false
      } else if (val == 2) {
        this.colaborador = false
        this.avaliador = true
        this.administrador = false
      } else if (val == 3) {
        this.avaliador = false
        this.colaborador = false
        this.administrador = true
      }
    },
  },
  mounted() {
    
  },
}
</script>